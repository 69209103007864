import NextLink from 'next/link';

import SectionListProps from 'components/HomePage/components/SectionBrowser/SectionListProps';
import { SectionSlot } from 'components/Toolkit/SectionSlot/SectionSlot';
import GoFurtherIcons from 'components/HomePage/Icons/GoFurtherIcons';

import SectionContainer from 'components/HomePage/components/SectionBrowser/styles/SectionContainer';
import { OptionalNextLink } from 'components/Toolkit/OptionalNextLink/OptionalNextLink';

function GoFutherSectionList({ sections }: SectionListProps) {
  return (
    <SectionContainer data-testid="go-further-list">
      {sections.map((section, index) => {
        const isExternal = section.hasOwnProperty('link');
        const link = isExternal ? section.link : `/${section.name}`;

        return (
          <li key={index}>
            <OptionalNextLink
              href={link}
              isExternal={isExternal}
              Link={NextLink}
              nofollow={false}
            >
              <SectionSlot
                icon={GoFurtherIcons[section.name]}
                text={section.displayName}
              />
            </OptionalNextLink>
          </li>
        );
      })}
    </SectionContainer>
  );
}

export { GoFutherSectionList };
