import type { ReactNode } from 'react';
import { Link as ToolkitLink } from 'components/Toolkit/Button/Link';
import type { LinkProps as ToolkitLinkProps } from 'components/Toolkit/Button/SharedLink';
import type { NextLinkProps, LinkProps, Url } from 'types';

interface OptionalLinkWrapperProps extends Omit<LinkProps, 'href'> {
  children: ReactNode | string;
  Link?: NextLinkProps;
  href?: Url | string;
  isExternal?: boolean;
  isUnderscoreBlankIfExternal?: boolean;
  toolkitLinkProps?: ToolkitLinkProps;
  nofollow?: boolean;
}

function OptionalNextLink({
  children,
  Link,
  href,
  isExternal = false,
  toolkitLinkProps,
  nofollow = true,
  ...args
}: OptionalLinkWrapperProps) {
  return (
    <>
      {Link && href ? (
        isExternal ? (
          <a
            href={href as string}
            target="_blank"
            rel={`noopener noreferrer${nofollow ? ' nofollow' : ''}`}
          >
            {typeof children === 'string' ? (
              <ToolkitLink {...toolkitLinkProps} isFakeLink={true}>
                {children}
              </ToolkitLink>
            ) : (
              children
            )}
          </a>
        ) : (
          <Link href={href} {...args} legacyBehavior>
            <a>
              {typeof children === 'string' ? (
                <ToolkitLink {...toolkitLinkProps} isFakeLink={true}>
                  {children}
                </ToolkitLink>
              ) : (
                children
              )}
            </a>
          </Link>
        )
      ) : (
        <>
          {typeof children === 'string' ? (
            <ToolkitLink {...toolkitLinkProps} isFakeLink={true}>
              {children}
            </ToolkitLink>
          ) : (
            children
          )}
        </>
      )}
    </>
  );
}

export { OptionalNextLink };
