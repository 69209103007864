import {
  BuyingGuide64,
  FindaDealer64,
  CarValuationTool64,
  SellMyCar64,
  VehicleHistoryCheck64,
  Blog64,
  ElectricCars64,
  CarsFromDealerships64,
} from 'components/Toolkit/Base64/Sections';
interface Map {
  [name: string]: JSX.Element;
}

const GoFurtherIcons: Map = {
  'find-a-dealer': <FindaDealer64 altText="Find a dealer" />,
  'sell-my-car': <CarsFromDealerships64 altText="Sell My Car" />,
  'instant-offer': <SellMyCar64 altText="Get an Instant Offer" />,
  'value-my-car': <CarValuationTool64 altText="Value My car" />,
  'buying-guide': <BuyingGuide64 altText="Buying Guide" />,
  'greenlight-history-check': (
    <VehicleHistoryCheck64 altText="Car History Check" />
  ),
  'electric-cars': <ElectricCars64 altText="Electric Car Hub" />,
  blog: <Blog64 altText="" />,
};

export default GoFurtherIcons;
